<template>
  <div class="container">
    <h1 style="text-align:center;">טופס הגדלת שעות</h1>
    <div class="warpper">
      <div class="field">
        <p>סניף</p>
        <InputText
          v-model="values.branche"
          type="text"
          placeholder="סניף"
          style="width:100%"
          disabled
        />
      </div>
      <div class="field">
        <p>ת.ז/דרכון</p>
        <InputText
          v-model="values.idNumber"
          type="text"
          placeholder="תז/דרכון"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>שם מלא של העובד/ת</p>
        <InputText
          v-model="values.fullName"
          type="text"
          placeholder="שם מלא של העובד/ת"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>בחר מחלקה</p>
        <Dropdown
          :options="departments"
          v-model="values.department"
          placeholder="בחר מחלקה"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>מכסת שעות התקן החודשי שמופיעות לעובד כרגע</p>
        <InputNumber v-model="values.michsat_shaot" />
        <small style="color:red; font-weight:bold;"
          >נא לבדוק בפתק של העבודה כמה שעות מקסימום יש לעובד כרגע. ראב דוגמה:
          לחץ כאן</small
        >
      </div>
      <div class="field">
        <p>מספר שעות להגדלה</p>
        <InputNumber v-model="values.increasing_hours_qnt" />
        <small
          v-if="values.increasing_hours_qnt > 0"
          style="color:red; font-weight:bold;"
          >נא לשים לב! סה"כ תקן שעות חודשיות יהיו:
          {{ values.michsat_shaot + values.increasing_hours_qnt }}</small
        >
      </div>
      <div class="field">
        <p>סיבה להגדלת שעות</p>
        <Textarea
          v-model="values.reason"
          rows="5"
          cols="30"
          placeholder="נא לרשום את הסיבה להגדלת השעות לעובד/ת"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>שם מנהל הסניף המבקש</p>
        <InputText
          v-model="values.ownerName"
          type="text"
          placeholder="שם מנהל הסניף המבקש"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>כתובת דוא"ל לקבלת עדכון</p>
        <InputText
          v-model="values.email"
          type="text"
          placeholder="שם מנהל הסניף המבקש"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p style="color:red;">מטופל ע"י</p>
        <Dropdown
          :options="chargeOptions"
          v-model="values.charge"
          placeholder="מטופל על ידי"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p style="color:red;">סטטוס</p>
        <Dropdown
          :options="statusOptions"
          v-model="values.status"
          placeholder="סטטוס טיפול"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p style="color:red;">הודעה לסניף</p>
        <Textarea
          v-model="values.msg"
          rows="5"
          cols="30"
          placeholder="הודעה זו תשלח בגוף המייל לפותח הבקשה"
        />
      </div>
    </div>

    <Button
      label="עדכן"
      class="p-button-success send-btn"
      @click="handleSubmit"
    />
    <Loader v-if="isPending" />
  </div>
</template>

<script>
/* eslint-disable */
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
import InputNumber from "primevue/inputnumber";
import swal from "sweetalert";
import {projectFirestore,projectFunctions} from '@/firebase/config'
import { onMounted, ref } from "vue";
import {update_status_counter,convert_type_he_to_en} from '@/Methods/office_request_funcs'
import Loader from '../loader/Loader.vue'
import router from '../../../../../../router';
export default {
  components: { InputText, Dropdown, Textarea, InputNumber,Loader },
  props:['docId','data'],
  setup(props) {
    const isPending = ref(false)
    const error = ref("");
    const values = ref({});
    const chargeOptions = ref(["שירז","אירה","עדי"]);
    const statusOptions = ref(["חדש", "ממתין", "טופל"]);
    const old_status=ref()
    const validation = () => {
      for (let key in values.value) {
        if (key == "idNumber") {
          if (values.value[key].length <7) {
            error.value = "נא למלא מספר זהות או דרכון תקין";
            return false;
          }
        }
        if (key == "fullName") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא שם מלא של העובד";
            return false;
          }
        }
        if (key == "department") {
          if (values.value[key].length == 0) {
            error.value = "נא לבחור מחלקה";
            return false;
          }
        }
        if (key == "michsat_shaot") {
          if (values.value[key] == 0) {
            error.value = "נא להזין מכסת שעות";
            return false;
          }
        }
        if (key == "increasing_hours_qnt") {
          if (values.value[key] == 0) {
            error.value = "נא להזין מספר שעות להגדלה";
            return false;
          }
        }
        if (key == "reason") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא סיבה להגדלת שעות שעות ";
            return false;
          }
        }
        if (key == "ownerName") {
          if (values.value[key].length < 1) {
            error.value = "נא למלא את שם פותח הבקשה";
            return false;
          }
        }
        if (key == "email") {
          if (
            values.value[key].length == 0 ||
            !values.value[key].includes("@")
          ) {
            error.value = "נא למלא כתובת מייל תקינה";
            return false;
          }
        }
      }
      return true;
    };

    const handleSubmit = async () => {
      if (!validation()) {
        swal("בעיה", `${error.value}`, "warning");
      } else {
        delete values.value.createdAt
        const request_collection = convert_type_he_to_en(values.value.type)
        console.log(request_collection);
        console.log(props.docId);
        await projectFirestore.collection('Applications').doc(props.docId).
        collection(request_collection).doc(values.value.id).set(values.value,{merge:true})
        if(old_status.value!=values.value.status){
          await update_status_counter(old_status.value,values.value.status,props.docId)
        }
        sendEmailToUser();
        swal("הצלחה", "הטופס עודכן בהצלחה", "success");
        router.go(-1)
      }
    };

    const departments = ref([
      "מנהל/ת",
      "סגן/ית",
      "א.מעדנייה",
      "מעדנייה",
      "א.קצבייה",
      "קצבייה",
      "ירקות",
      "סדרן/ית",
      "קופאי/ית",
      "ק.ראשי/ית",
      "מחסנאי/ית",
      "ניקיון",
      "שומר/ת"
    ]);

    onMounted(() => {
       isPending.value = true
      setTimeout(() => {
        if(props.data){
          values.value=props.data
          old_status.value = values.value.status
           isPending.value = false
        }
      }, 1000);
    });

    function sendEmailToUser() {
      projectFunctions.httpsCallable('send_email')({
        html: `<div>
                <h3>
                    שלום ${values.value.ownerName},
                </h3>
                <h3>
                    בקשתך המשרדית לעובד: ${values.value.fullName}
                </h3>
                <h3>
                    עבור: ${values.value.type}
                </h3>
                <h3>
                    לסניף/אתר ${values.value.branche}
                </h3>
                <h3>
                    בסטטוס ${values.value.status}
                </h3>
                <h3>
                    הערות: ${values.value.msg}
                </h3>
                <h3 style="color:red;">
                    נא לשים לב להמשך עדכונים שיתקבלו אוטמטית מהמערכת בכל שלבי הטיפול!
                </h3>
            </div>`,
        destinations:[`${values.value.email}`],
        from:"מערכת לבקשות משרדיות",
        title:"עדכון סטטוס בקשה משרדית"
      }) 
    }
    return { values, departments, handleSubmit, chargeOptions, statusOptions,isPending };
  }
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.warpper {
  width: 50%;
  height: calc(100% - 100px);
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
.field {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5px;
  margin-top: 15px;
}

.field p {
  font-size: 1.1rem;
}
.send-btn {
  margin-top: 5px;
  width: 50%;
}
input[type="date"] {
  height: 35px;
  border: 0.5px solid lightgrey;
  border-radius: 3px;
}
input[type="date"]:hover {
  border: 1.1px solid lightblue;
}
input[type="date"]:focus {
  outline: none;
  border: 1.1px solid lightblue;
}
input[type="time"] {
  height: 35px;
  border: 0.5px solid lightgrey;
  border-radius: 3px;
}
input[type="time"]:hover {
  border: 1.1px solid lightblue;
}
input[type="time"]:focus {
  outline: none;
  border: 1.1px solid lightblue;
}

@media screen and (max-width: 600px) {
  .container {
    justify-content: unset;
    overflow: hidden;
    background: var(--secondary);
    color: #fff;
  }
  .warpper {
    width: 100%;
    border: none;
    overflow-y: auto;
    flex-shrink: 0;
  }
  .send-btn {
    width: 100%;
  }
  .upload-btn {
    width: 100%;
  }
}
.example {
  width: 400px;
  height: 400px;
}
.example img {
  width: 100%;
  height: 100%;
}
</style>
